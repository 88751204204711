import React from 'react';
import Header from '../components/header';
import SinglePageHeader from '../components/singlepageheader';
import Footer from '../components/footer';

const Thankyou = () => {
    return(
        <div className="container">
            <Header />
            <SinglePageHeader page="Thank You" subtitle={() => (
                <>
                    <p>Thank you for your interest in Pooja Services</p>
                    <p>Agent from Pooja Services will reach you with the best quote possible !!!</p>
                </>
            )} />

            <Footer />
        </div>
    )
}

export default Thankyou;